import React from 'react';
import { useField } from 'formik';
import { CheckboxElement } from './CheckboxElement';

export enum LabelPosition {
  pre = 'pre',
  post = 'post',
}

interface Props {
  label: string;
  labelPos?: LabelPosition;
  name: string;
  id: string;
  value: any;
}

export const Checkbox: React.FC<Props> = props => {
  const labelPos = props.labelPos || LabelPosition.post;
  const [field, meta, helpers] = useField({
    name: props.name,
    type: 'checkbox',
  });
  const { setValue } = helpers;
  return (
    <CheckboxElement {...props} onChange={e => setValue(e.target.checked)} />
  );
};

export default Checkbox;
