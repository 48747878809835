import React from 'react';

export enum LabelPosition {
  pre = 'pre',
  post = 'post',
}

interface Props {
  label: string;
  labelPos?: LabelPosition;
  name: string;
  id: string;
  value: any;
  onChange: Function;
}

export const CheckboxElement: React.FC<Props> = props => {
  const labelPos = props.labelPos || LabelPosition.post;

  return (
    <div
      className={`custom-control custom-checkbox ${labelPos.toLocaleLowerCase()}-label`}
    >
      <input
        type="checkbox"
        className="custom-control-input"
        id={props.id || props.name || 'customCheck1'}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
      />
      <label
        className="custom-control-label"
        htmlFor={props.id || props.name || 'customCheck1'}
      >
        {props.label}
      </label>
    </div>
  );
};
