import React from 'react';
import { Login } from '../components/Login';
import { navigate } from 'gatsby';
import MainLayout from '../layouts/mainLayout';
import { isLoggedIn } from '../services/auth.api';
import { OrderType } from 'models';
import { getQueryParams } from '../utils';

interface Props {
  location: {
    search: string;
  };
}

export default ({ location }: Props) => {
  const queryParams = getQueryParams(location.search);
  const orderType = queryParams.type || OrderType.New;

  if (isLoggedIn()) {
    navigate(-1);
    orderType === OrderType.OneOff
      ? navigate(`/checkout?ot=${orderType}`, { replace: true })
      : navigate('/account/dashboard/', { replace: true });
    return <></>;
  }

  return (
    <MainLayout withTitle>
      <Login type={orderType} />
    </MainLayout>
  );
};
